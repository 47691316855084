import React from 'react';
import './BlogPagesComponent.scss';
import { useParams } from 'react-router-dom';
import {fetchData} from '../services';
import {Card} from 'react-bootstrap';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

function withParams(Component){
  return props => (<Component props={props} params={useParams()} />);
}

class EntriesComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isFetching: false,
      categoryName: '',
      entries: [],
    }
    this.fetchContent = this.fetchContent.bind(this);
  }

  componentDidMount(){
    this.fetchContent();
  }

  fetchContent(){
    const {category} = this.props.params;
    if(0===parseInt(category)) this.fetchAllEntries();
    else {
      fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/blog-category/${category}/entries`)
        .then(data=>data.success && this.setState({
          categoryName: data.categoryName,
          entries: data.entries,
        }))
        .catch(err => console.warn(err));
    }
  }

  fetchAllEntries(){
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/blog-category/all-entries`)
      .then(data=>data.success && this.setState({
        categoryName: data.categoryName,
        entries: data.entries,
      }))
      .catch(err => console.warn(err));
  }

  renderEntry(e,k){
    return (
      <Card>
        <Card.Body>
          <Card.Title>{e.title}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{e.categories.map(e => `${e}, `)}</Card.Subtitle>
          <Card.Text>{e.initialContent}</Card.Text>
          <Card.Link href={`/historie/e/${e.id}/${e.queryString}`}>więcej</Card.Link>
        </Card.Body>
      </Card>
    )
  }

  render(){
    const {entries,isFetching} = this.state;
    return (
      <div className="blog-entries">
        {!isFetching && !entries.length && (<h2>Brak wpisów w kategorii</h2>)}
        {entries.map((e,k) => this.renderEntry(e,k))}
      </div>
    );
  }
}

export default withParams(EntriesComponent);
