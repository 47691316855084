import React from 'react';
import "./AccessTypesComponent.scss";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {fetchData} from '../../services';
const {REACT_APP_BACKEND_URL} = process.env;

export default class NewAccessTypeComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      name: '',
      days: 0,
      cost: 0,
    }
    this.refreshForm = this.refreshForm.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.handleCreateUserAccessType = this.handleCreateUserAccessType.bind(this);
  }

  handleCreateUserAccessType(ev){
    ev.preventDefault();
    let {name,days,cost} = this.state;
    let data = {'name':name,'days':days,'cost':cost};
    fetchData(`${REACT_APP_BACKEND_URL}/api/user-access/type/new`,'POST',data)
      .then(data=>{
        if(data.success) this.refreshForm();
        else window.alert(data.errorMessage);
      })
      .catch(err=>console.warn(err));
  }

  refreshForm(){
    this.setState({name:'',days:0,cost:0})
  }

  updateItem(item,ev){
    this.setState({[item]:ev.target.value});
  }

  renderUserAccessTypeForm(){
    return (
      <Form onSubmit={this.handleCreateUserAccessType} autoComplete="no">
        <Form.Group>
          <Form.Control
            placeholder="Nazwa pakietu"
            type="text"
            name="name"
            autoComplete="false"
            value={this.state.name}
            onChange={(ev)=>this.updateItem('name',ev)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            placeholder="Ilość dni trwania pakietu"
            type="number"
            name="days"
            autoComplete="false"
            value={this.state.days}
            onChange={(ev)=>this.updateItem('days',ev)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            placeholder="Cena pakietu w PLN"
            type="number"
            name="cost"
            autoComplete="false"
            value={this.state.cost}
            onChange={(ev)=>this.updateItem('cost',ev)}
          />
        </Form.Group>
        <Button variant="danger" type="submit">Stwórz</Button>
      </Form>
    )
  }

  render(){
    return (
      <div className="user-form">
        <h3>Nowy pakiet dostępu:</h3>
        {this.renderUserAccessTypeForm()}
      </div>
    )
  }
}
