import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';

function MenuComponent() {
  return (
    <Accordion defaultActiveKey={['0']}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Użytkownicy</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li><Link to="/admin">Lista użytkowników</Link></li>
            <li><Link to="/admin/new-user">Nowy użytkownik</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Dostępne portale</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li><Link to="/admin/portals">Lista portali</Link></li>
            <li><Link to="/admin/new-portal">Dodaj nowy portal</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Relacje</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li><Link to="/admin/relation-types">Lista relacji</Link></li>
            <li><Link to="/admin/new-relation-type">Dodaj nową relację</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Pakiety</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li><Link to="/admin/access-types">Lista pakietów</Link></li>
            <li><Link to="/admin/new-access-type">Dodaj nowy pakiet</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Płatności</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li><Link to="/admin/payments">Wykaz płatności</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Ustawienia strony</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li><Link to="/admin/page-settings">Podstawowe ustawienia</Link></li>
            <li><Link to="/admin/static-pages">Treść stron statycznych</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>Blog</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li><Link to="/admin/blog-categories">Kategorie</Link></li>
            <li><Link to="/admin/entries/to-decide">Wpisy do moderacji</Link></li>
            <li><Link to="/admin/entries/approved">Wpisy zaakceptowane</Link></li>
            <li><Link to="/admin/entries/denied">Wpisy odrzucone</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <ul>
        <li><Link to="/logout">Wyloguj</Link></li>
      </ul>
    </Accordion>
  )
}

export default MenuComponent;
