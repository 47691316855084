import React from 'react';
import "./FindOutComponent.scss";
import {Outlet,Link,Navigate} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import LoaderComponent from './Loader/LoaderComponent';
import { fetchData } from './services';

const { REACT_APP_BACKEND_OPEN_URL } = process.env;

export default class FindOutComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      cateogries: [],
      isAdmin: false,
      isLoggedIn: false,
      user: null,
      isLoading: false,
    }
    this.fetchCategories = this.fetchCategories.bind(this);
  }

  componentDidMount(){
    this.verifyUser();
    this.fetchCategories();
  }

  verifyUser(){
    this.setState({
      isLoading: true,
    })
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/me`,false,false,false)
      .then(data=>data.success && this.setState({
        isLoggedIn: data.isLoggedIn,
        isAdmin: data.isAdmin,
        user: data.user,
      }))
      .catch(err => console.warn(err))
      .finally(()=>this.setState({isLoading:false}));
  }

  fetchCategories(){
    this.setState({
      isLoading: true,
    })
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/blog-category/all`)
      .then(data=>data.success && this.setState({
        categories: data.categories,
      }))
      .catch(err => console.warn(err))
      .finally(()=>this.setState({isLoading:false}));
  }

  renderCategory(c,l){
    return (
      <li key={`link-${l}`}><Link to={`/historie/c/${c.id}/${c.queryString}`}>{c.name}</Link></li>
    );
  }

  renderCategories(){
    const {categories} = this.state;
    return (
      <ul>
       {categories?.map((c,l) => this.renderCategory(c,l))}
      </ul>
    );
  }

  render() {
    const {isLoggedIn,isAdmin,user,isLoading} = this.state;
    return (
      <main>
        {isLoading && (<LoaderComponent />)}
        {isAdmin && (<Navigate to="/admin" redirect={true} />)}
        {isLoggedIn && (<Navigate to="/find-out/app" redirect={true} />)}
        <aside className="left-aside">Miejsce na Twoją reklamę</aside>
        <section className="main-section">
          {<Outlet isLoggedIn={isLoggedIn} user={user}/>}
        </section>
        <aside className="right-aside">
          <h5>Historie randkowe</h5>
          {this.renderCategories()}
          {isLoggedIn && (<Link to="/historie/nowa" className="add-new-story"><Button variant="danger">Dodaj nową historię</Button></Link>)}
        </aside>
      </main>
    );
  }
}
