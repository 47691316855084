import React from 'react';
import "./PortalsComponent.scss";
import {Table,Button,Form} from 'react-bootstrap';
import {fetchData} from '../../services';
import LoaderComopnent from '../../Loader/LoaderComponent';
const {REACT_APP_BACKEND_URL,REACT_APP_BACKEND_OPEN_URL} = process.env;

export default class PortalsComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      portals: [],
      isLoading: false,
    }
    this.fetchPortals = this.fetchPortals.bind(this);
    this.renderPortals = this.renderPortals.bind(this);
    this.updateOrderNumber = this.updateOrderNumber.bind(this);
    this.removePortal = this.removePortal.bind(this);
  }

  componentDidMount(){
    this.fetchPortals();
  }

  fetchPortals(){
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_URL}/api/portals`)
      .then(data=>this.setState({
        portals:data.portals,
      }))
      .catch(err=>console.warn(err))
      .finally(()=>this.setState({isLoading:false}));
  }

  removePortal(id){
    if(window.confirm("Czy usunąć?")){
      this.setState({isLoading:true});
      fetchData(`${REACT_APP_BACKEND_URL}/api/portals/${id}`,'DELETE')
        .then(data=>this.fetchPortals())
        .catch(err=>console.warn(err))
        .finally(()=>this.setState({isLoading:false}));
    }
  }

  updateOrderNumber(ev,portalId){
    const {target:{value}} = ev;
    const data = {'orderNumber':value};
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_URL}/api/portals/${portalId}/update-on`,'PATCH',data)
      .then(data=>this.fetchPortals())
      .catch(err=>console.warn(err.errorMessage))
      .finally(()=>this.setState({isLoading:false}));
  }

  renderSinglePortal(p,k){
    const {isLoading} = this.state;
    return (
      <tr key={k}>
        <td>
          {k+1}
          <Button variant="danger" onClick={()=>this.removePortal(p.id)}>Usuń</Button>
        </td>
        <td>
          <Form.Control
            type="number"
            onChange={(ev)=>this.updateOrderNumber(ev,p.id)}
            value={p.orderNumber || 1}
            disabled={isLoading}
          />
        </td>
        <td>{p.name}</td>
        <td className="portal-logo">
          <img src={`${REACT_APP_BACKEND_OPEN_URL}/api/portals/logo/${p.id}`} alt="Logo" />
        </td>
        <td>
          {p.relationsNumber}
        </td>
      </tr>
    )
  }

  renderPortals(){
    return (
      <Table striped bordered hover>
        <thead>
          <tr><th>LP</th><th>Kolejność wyświetlania</th><th>Nazwa</th><th>Logo</th><th>Ilość relacji</th></tr>
        </thead>
        <tbody>
          {this.state.portals.map((p,k) => this.renderSinglePortal(p,k))}
        </tbody>
      </Table>
    )
  }

  render(){
    const {isLoading} = this.state;
    return isLoading ? (<LoaderComopnent/>) : (<div className="portals-list">
        <h3>Dostępne portale:</h3>
        {this.renderPortals()}
      </div>
    );
  }
}
