import React from 'react';
import './BlogPagesComponent.scss';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import Logo from '../assets/logo_blank.png';
import { Outlet, Link, Navigate, useParams } from 'react-router-dom';
import {fetchData} from '../services';
import LoaderComponent from '../Loader/LoaderComponent';
import GoogleStore from '../assets/store-google.png';
import AppStore from '../assets/store-ios.png';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

function withParams(Component){
  return props => (<Component props={props} params={useParams()} />);
}

class BlogPagesComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isFetching: false,
      categories: [],
      isLoggedIn: false,
      isAdmin: false,
      user: null,
    }
    this.fetchCategories = this.fetchCategories.bind(this);
    this.verifyUser = this.verifyUser.bind(this);
  }

  componentDidMount(){
    this.verifyUser();
    this.fetchCategories();
  }

  verifyUser(){
    this.setState({isFetching: true});
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/me`,false,false,false)
      .then(data=>data.success && this.setState({
        isLoggedIn: data.isLoggedIn,
        isAdmin: data.isAdmin,
        user: data.user,
      }))
      .catch(err => console.warn(err))
      .finally(()=>this.setState({isFetching:false}));
  }

  fetchCategories(){
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/blog-category/all`)
      .then(data=>data.success && this.setState({
        categories: data.categories,
      }))
      .catch(err => console.warn(err));
  }

  renderCategory(c,n){
    const {category} = this.props.params;
    const isDisabled = c.id === parseInt(category);
    return (
      <Nav.Link disabled={isDisabled} key={`link-${n}`} href={`/historie/c/${c.id}/${c.queryString}`}>{c.name}</Nav.Link>
    );
  }

  renderCategoriesMenu(){
    const {categories} = this.state;
    const {category} = this.props.params;
    const allCat = 0 === parseInt(category);
    return (
      <Navbar bg="light" data-bs-theme="light">
        <Container>
          <Navbar.Brand href="#home">Kategorie:</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link disabled={allCat} href={`/historie/c/0/wszystkie-kategorie`}>Wszystkie kategorie</Nav.Link>
            {categories.map((c,n) => this.renderCategory(c,n))}
          </Nav>
        </Container>
      </Navbar>
    );
  }

  render(){
    const {isFetching,isAdmin,isLoggedIn,user} = this.state;
    return (
      <main className="front-end">
        {isFetching && (<LoaderComponent />)}
        {isAdmin && (<Navigate to="/admin" redirect={true} />)}
        <header>
          <Link to="/">
            <img src={Logo} alt="Find Out!" />
          </Link>
          <nav>
            <Link reloadDocument to="/pakiety">Pakiety</Link>
            <Link reloadDocument to="/bezpieczenstwo">Bezpieczeństwo</Link>
            <Link reloadDocument to="/blog">Blog</Link>
          </nav>
          {!isLoggedIn && (
            <Link to="/find-out" className="login-link">
              <Button>Logowanie</Button>
            </Link>
          )}
          {isLoggedIn && user && (
            <Link to="/find-out/app" className="user">Użytkownik: {user}</Link>
          )}
        </header>
        <section className="love-stories">
          <h1>Historie randkowe</h1>
          {this.renderCategoriesMenu()}
          <Outlet />
        </section>
        <footer>
          <h5>Do pobrania</h5>
          <div className="mobile-stores">
            <img src={GoogleStore} alt="Google Store" />
            <img src={AppStore} alt="App Store" />
          </div>
          <nav>
            <ul>
              <li><Link reloadDocument to="/o-nas">O nas</Link></li>
              <li><Link reloadDocument to="/reklama">Reklama</Link></li>
              <li><Link reloadDocument to="/kontakt">Kontakt</Link></li>
              <li><Link reloadDocument to="/pomoc">Pomoc</Link></li>
            </ul>
          </nav>
        </footer>
      </main>
    )
  }
}

export default withParams(BlogPagesComponent);
