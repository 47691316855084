import React from 'react';
import "./RegisterComponent.scss";
import Logo from '../assets/logo.png';
import {Alert,Toast} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import {fetchData} from '../services';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

function withParams(Component){
  return props => <Component {...props} params={useParams()}/>;
}

class ConfirmAccount extends React.Component {

  constructor(props){
    super(props);
    this.state={
      isFetching: false,
      hasResult: false,
      hasError: false,
      errorMessage: '',
    }
    this.fetchItem = this.fetchItem.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidMount(){
    const {id} = this.props.params;
    if(id){
      this.fetchItem(id);
    } else window.location.replace("/login");
  }

  fetchItem(){
    const {id} = this.props.params;
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/confirm/${id}`)
      .then(data => {
        if(!data.success){
          this.setState({
            hasError: true,
            errorMessage: data.message,
          })
        }
      })
      .finally(() => {
        this.setState({
          isFetching: false,
          hasResult: true,
        })
      })
      .catch(err=>console.warn(err));
  }

  renderContent(){
    const {isFetching,hasResult,hasError,errorMessage} = this.state;
    return (
      <div>
        <img src={Logo} className="logo" alt="Logo Klubu"/>
        {!isFetching && (
          !hasResult ? (
            <Alert variant="primary">Poczekaj na potwierdzenie konta</Alert>
          ) : (
            hasError ? (
              <Alert variant="warning">{errorMessage}</Alert>
            ) : (
              <Alert variant="success">Twoje konto zostało potwierdzoene, możesz się <Link to="/find-out/login">zalogować</Link></Alert>
            ))
          )}
      </div>
    )
  }

  render(){
    return (
      <div className="account-confirmation">
        {this.renderContent()}
      </div>
    )
  }
}

export default withParams(ConfirmAccount);
