import React from 'react';
import "./UsersComponent.scss";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {fetchData} from '../../services';
const {REACT_APP_BACKEND_URL} = process.env;

export default class NewUserComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      email: '',
    }
    this.refreshForm = this.refreshForm.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.handleCreateUser = this.handleCreateUser.bind(this);
  }

  handleCreateUser(ev){
    ev.preventDefault();
    let data = {'username':this.state.email};
    fetchData(`${REACT_APP_BACKEND_URL}/api/users/new`,'POST',data)
      .then(data=>{
        if(data.success) this.refreshForm();
        else window.alert(data.errorMessage);
      })
      .catch(err=>console.warn(err));
  }

  refreshForm(){
    this.setState({login:''});
  }

  updateItem(item,ev){
    this.setState({[item]:ev.target.value});
  }

  renderUserForm(){
    return (
      <Form onSubmit={this.handleCreateUser} autoComplete="no">
        <Form.Group>
          <Form.Control
            placeholder="Edres email użytkownika"
            type="email"
            name="email"
            autoComplete="false"
            value={this.state.email}
            onChange={(ev)=>this.updateItem('email',ev)}
          />
        </Form.Group>
        <Button variant="danger" type="submit">Stwórz</Button>
      </Form>
    )
  }

  render(){
    return (
      <div className="user-form">
        <h3>Nowy użytkownik:</h3>
        {this.renderUserForm()}
      </div>
    )
  }
}
