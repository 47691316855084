import React from 'react';
import {fetchData} from '../services';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

export default class Logout extends React.Component {

  componentDidMount(){
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/logout`,'POST')
      .then(()=>window.location.replace('/find-out'))
      .catch(err=>console.warn(err))
  }

  render(){
    return(<h2>Trwa wylogowywanie...</h2>);
  }
}
