import React from 'react';
import './HomeComponent.scss';
import { Button, Form } from 'react-bootstrap';
import Logo from '../../assets/logo_blank.png';
import { Link } from 'react-router-dom';
import {fetchData} from '../../services';
import { useParams } from 'react-router-dom';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

function withParams(Component){
  return props => (
    <Component {...props} params={useParams()} />
  )
}

class RelationChatComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isFetching: false,
      messages: [],
      interlocutor: null,
      message: '',
    }
    this.messagesView = React.createRef();
    this.renderChat = this.renderChat.bind(this);
    this.fetchChat = this.fetchChat.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.updateMessage = this.updateMessage.bind(this);
  }

  componentDidMount(){
    this.interval = setInterval(this.fetchChat,3000);
  }

  componentWillUnmount(){
    clearInterval(this.interval);
  }

  fetchChat(){
    const iid = this.props.params.interlocutorId;
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/user/api/chat/${iid}`)
      .then(data=>{
        if(data) this.setState({
          messages: data.messages,
          interlocutor: data.interlocutor,
        },() => this.scrollMessagesView());
      })
      .finally(()=>this.setState({isLoading:false}));
  }

  scrollMessagesView(){
    this.messagesView.current.scrollTo(0,2000);
  }

  sendMessage(ev){
    ev.preventDefault();
    const {message} = this.state;
    if(!message) return;
    const iid = this.props.params.interlocutorId;
    this.setState({isLoading:true});
    const data = {message};
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/user/api/chat/add/${iid}`,'POST',data)
      .then(data=>{
        if(data && data.success) this.setState({
          message: '',
        })
      })
      .finally(()=>this.setState({isLoading:false}));
  }

  renderMessage(m,k){
    return (
      <span key={k} className={`${m.isAuthor ? 'own' : ''}`}>{m.value}</span>
    )
  }

  renderPreviousMessages(){
    const {messages} = this.state;
    return (
      <div className="messages" ref={this.messagesView}>
        {!messages.length && (<h4>Rozpocznij rozmowę!</h4>)}
        {!!messages.length && messages.map((m,k) => this.renderMessage(m,k))}
      </div>
    )
  }

  updateMessage(ev){
    const {value} = ev.target;
    this.setState({
      message: value,
    })
  }

  renderSendForm(){
    const {message,isFetching} = this.state;
    return (
      <Form onSubmit={this.sendMessage}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Control type="text" placeholder="Wyślij wiadomość" value={message} onChange={this.updateMessage} />
          <Button type="submit" disabled={isFetching}>Wyślij >></Button>
        </Form.Group>
      </Form>
    )
  }

  renderChat(){
    return (
      <div className="chat">
        {this.renderPreviousMessages()}
        {this.renderSendForm()}
      </div>
    )
  }

  render(){
    return (
      <div className="main-component">
        <img src={Logo} alt="Logo" />
        {this.state.interlocutor && (<h5>Rozmawiasz z: {this.state.interlocutor}</h5>)}
        {this.renderChat()}
        <Link className="mainmenu" to="/find-out/app"><span>Menu główne</span></Link>
      </div>
    )
  }
}

export default withParams(RelationChatComponent);
