import React from 'react';
import { Form, Button, ListGroup } from 'react-bootstrap';
import '../HomeComponent.scss';
import '../Communicators.scss';
import Logo from '../../../assets/logo_blank.png';
import { Link } from 'react-router-dom';
import {fetchData} from '../../../services';
import Select from 'react-select';
import {communicators,settings,basicUser,basicUserKeys,communicatorsKeys,placeholders,disabledEdition} from '../relation.data';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

export default class EmptyRelationForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isLoading: false,
      relationTypes: [],
      portals: [],
      ...communicators,
      ...settings,
      ...basicUser,
    };
    this.fetchRelationTypes = this.fetchRelationTypes.bind(this);
    this.fetchPortals = this.fetchPortals.bind(this);
    this.updateRelation = this.updateRelation.bind(this);
    this.createRelation = this.createRelation.bind(this);
    this.renderRelation = this.renderRelation.bind(this);
    this.renderFormItem = this.renderFormItem.bind(this);
    this.updatePortal = this.updatePortal.bind(this);
    this.renderSelectPortal = this.renderSelectPortal.bind(this);
  }

  componentDidMount(){
    this.fetchRelationTypes();
    this.fetchPortals();
  }

  fetchRelationTypes(){
    this.setState({
      isLoading: true,
    })
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/user/api/relation/types`)
      .then(data=>{
        this.setState({
          relationTypes: data.relationTypes,
        })
      })
      .finally(() => {
        this.setState({isLoading:false});
      })
  }

  fetchPortals(){
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/user/api/portals`)
      .then(data=>{
        this.setState({
          portals: data.portals,
        })
      })
      .finally(()=>this.setState({isLoading:false}));
  }

  updateRelation(ev){
    const{target:{name,value}} = ev;
    this.setState({[name]:value});
  }

  createRelation(ev){
    this.props.onSubmit(ev,this.state);
  }

  renderRelation(rt,k){
    return (
      <label key={k}>
        <input
          type="radio"
          name="relationType"
          value={rt.id}
          onChange={this.updateRelation}
        />
        <span>{rt.name}</span>
      </label>
    )
  }

  renderFormItem(buk,k,isCommunicator=false){
    const {isLoading} = this.state;
    const isDisabledCheckbox = !this.state[buk];
    const classNames=['has-input'];
    if(isCommunicator) classNames.push(`${buk} is-communicator`);
    return (
      <div key={k}>
        <div className={classNames.join(" ")}>
          <Form.Control
            placeholder={placeholders[buk]}
            type="text"
            name={buk}
            value={this.state[buk]}
            disabled={!!isLoading || disabledEdition.indexOf(buk)>-1}
            onChange={this.updateRelation}
          />
          {isCommunicator && (<span className="icon"/>)}
        </div>
      </div>
    )
  }

  updatePortal(item){
    this.setState({
      portal: item.value,
    })
  }

  renderSelectPortal(){
    const { portals, isLoading } = this.state;
    let options = portals.map((p,n) => (
      {
        value: p.id,
        label: p.name,
      }
    ));
    let formatOptionLabel = portal => (
      <div className="portal-option">
        <img src={`${REACT_APP_BACKEND_OPEN_URL}/api/portals/logo/${portal.value}`} alt="portal-image" />
        <span>{portal.label}</span>
      </div>
    );
    return (
      <Select
        options={options}
        formatOptionLabel={formatOptionLabel}
        isLoading={isLoading}
        onChange={this.updatePortal}
        placeholder="Wybierz portal"
      />
    )
  }

  renderDescription(){
    const {portal,portals} = this.state;
    const portalObject = portals.find(p => p.id === portal);
    let message = "Kliknij aby odnaleźć wszystkie osoby które ";
    message+= portalObject ? "w portalu "+portalObject.name+" " : "";
    message+="również pozostają w relacji z osobą spełniającą poniższe kryteria:";
    let fields = Object.keys(placeholders).filter(p => this.state[p]);
    return fields.length ? (
      <div className="relation-explanation">
        <small>{message}</small>
        <ListGroup>
          {Object.keys(placeholders).filter(p => this.state[p]).map((p,k) => (
            <ListGroup.Item key={`descriptionitem${k}`}><small>{placeholders[p]}: {this.state[p]}</small></ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    ) : (<></>)
  }

  render(){
    const {relationTypes} = this.state;
    return (
      <div className="new-relation-component">
        <img src={Logo} alt="Logo" className="logo" />
        <h3>Mój partner</h3>
        <Form onSubmit={this.createRelation}>
          {basicUserKeys.map((buk,k) => this.renderFormItem(buk,k))}
          <h3>Komunikatory</h3>
          {communicatorsKeys.map((ck,k) => this.renderFormItem(ck,k,true))}
          <h3>Określ typ relacji</h3>
            <div className="relation-options">
              {relationTypes.map((rt,n) => this.renderRelation(rt,n))}
            </div>
          <h3>Wybierz portal</h3>
          {this.renderSelectPortal()}
          {this.renderDescription()}
          <Button className="ctf" variant="danger" type="submit">Click To Find Out!</Button>
          <Link className="mainmenu" to="/find-out/app"><span>Menu główne</span></Link>
        </Form>
      </div>
    );
  }
}
