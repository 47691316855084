import React from 'react';
import './HomeComponent.scss';
import SelectPackageComponent from './SelectPackageComponent';
import LoaderComponent from '../../Loader/LoaderComponent';
import {Outlet,Navigate} from 'react-router-dom';
import {fetchData} from '../../services';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

export default class HomeComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      usedSingleCheck: false,
      hasPackage: false,
      isLoading: false,
      redirectToAdmin: false,
      username: '',
    }
    this.fetchUser = this.fetchUser.bind(this);
  }

  componentDidMount(){
    this.fetchUser();
  }

  fetchUser(){
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/user/api/me/details`)
      .then(data=>{
        if(data) this.setState({
          redirectToAdmin: data.isAdmin,
          usedSingleCheck: data.consumedSingleCheck,
          hasPackage: data.hasPackage,
          username: data.username,
        })
      })
      .finally(()=>this.setState({isLoading:false}));
  }

  render(){
    const {usedSingleCheck,hasPackage,isLoading,redirectToAdmin,username} = this.state;
    return (
      <div className="home-component">
        {redirectToAdmin && (<Navigate to="/admin" redirect="true" />)}
        {isLoading ? <LoaderComponent /> :
          !hasPackage ? (<SelectPackageComponent usedSingleCheck={usedSingleCheck} username={username} />) : (<Outlet />)}
      </div>
    );
  }
}
