import React from 'react';
import "./PortalsComponent.scss";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
const {REACT_APP_BACKEND_URL} = process.env;

export default class NewPortalComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      name: '',
      logo: null,
    }
    this.refreshForm = this.refreshForm.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.handleCreatePortal = this.handleCreatePortal.bind(this);
    this.updateLogo = this.updateLogo.bind(this);
  }

  handleCreatePortal(ev){
    ev.preventDefault();
    let formData = new FormData();
    formData.append('logo',this.state.logo);
    formData.append('name',this.state.name);
    // todo: move fetch formData to services
    fetch(`${REACT_APP_BACKEND_URL}/api/portals/new`,{
      'credentials':'include',
      method: 'POST',
      body: formData,
    })
      .then(data=>data.json())
      .then(data=>{
        if(data.success) this.refreshForm();
        else window.alert(data.errorMessage);
      })
      .catch(err=>console.warn(err));
  }

  refreshForm(){
    this.setState({name:'',logo:null})
  }

  updateItem(item,ev){
    this.setState({[item]:ev.target.value});
  }

  updateLogo(ev){
    if(ev.target.files){
      this.setState({'logo':ev.target.files[0]});
    }
  }

  renderUserForm(){
    return (
      <Form onSubmit={this.handleCreatePortal} autoComplete="no">
        <Form.Group>
          <Form.Control
            placeholder="Nazwa portalu"
            type="text"
            name="name"
            autoComplete="false"
            value={this.state.name}
            onChange={(ev)=>this.updateItem('name',ev)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            placeholder="Logo portalu"
            type="file"
            name="logo"
            onChange={(ev)=>this.updateLogo(ev)}
          />
        </Form.Group>
        <Button variant="danger" type="submit">Stwórz</Button>
      </Form>
    )
  }

  render(){
    return (
      <div className="user-form">
        <h3>Nowy portal:</h3>
        {this.renderUserForm()}
      </div>
    )
  }
}
