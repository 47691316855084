import React from 'react';
import './BlogPagesComponent.scss';
import { useParams } from 'react-router-dom';
import {fetchData} from '../services';
import {Card} from 'react-bootstrap';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

function withParams(Component){
  return props => (<Component props={props} params={useParams()} />);
}

class SingleEntry extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isFetching: false,
      entry: {},
    }
    this.fetchContent = this.fetchContent.bind(this);
  }

  componentDidMount(){
    this.fetchContent();
  }

  fetchContent(){
    const {entry} = this.props.params;
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/blog-category/${entry}`)
      .then(data=>data.success && this.setState({
        entry: data.entry,
      }))
      .catch(err => console.warn(err));
  }

  renderEntry(){
    const {entry} = this.state;
    return (
      <Card>
        <Card.Body>
          <Card.Title>{entry.title}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Kategorie: {entry.categories?.map(e => `${e}, `)}</Card.Subtitle>
          <Card.Text>{entry.content}</Card.Text>
        </Card.Body>
      </Card>
    )
  }

  render(){
    return (
      <div className="blog-entry">
        {this.renderEntry()}
      </div>
    );
  }
}

export default withParams(SingleEntry);
