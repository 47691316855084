export function fetchData(url,method,data,shouldRedirect=true){
  let request = {
    "credentials": "include",
  }
  if(data){
    request['body'] = JSON.stringify(data);
  }
  request['method'] = method || 'GET';
  request['headers'] = {
    "Content-Type": "application/json",
  }
  return fetch(url,request)
    .then(data=>{
      if(data && data.json){
        if(401===parseInt(data.status) && shouldRedirect){
          window.location.replace("/find-out/login");
          return {};
        } else if(403===parseInt(data.status) && shouldRedirect){
          window.location.replace("/");
        } else return data.json()
      }
    })
    .catch(error => {
      console.warn('error from services: ',error);
    })
}
