import React from 'react';
import {Form,Alert,Button} from 'react-bootstrap';
import {fetchData} from '../services';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

export default class NewBlogPageComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isFetching: false,
      categories: [],
      newEntryTitle: '',
      newEntryContent: '',
      newEntryCategoriesIds: [],
      isSubmitted: false,
    }
    this.fetchCategories = this.fetchCategories.bind(this);
    this.renderNewStoryForm = this.renderNewStoryForm.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.submitNewEntry = this.submitNewEntry.bind(this);
    this.toggleCategory = this.toggleCategory.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  componentDidMount(){
    this.fetchCategories();
  }

  fetchCategories(){
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/blog-category/all`)
      .then(data=>data.success && this.setState({
        categories: data.categories,
      }))
      .catch(err => console.warn(err));
  }

  updateItem(ev){
    const {target:{name,value}} = ev;
    this.setState({
      [name]: value,
    })
  }

  toggleCategory(ev){
    const {newEntryCategoriesIds} = this.state;
    const {target:{value}} = ev;
    const intVal = parseInt(value);
    let categories = newEntryCategoriesIds.indexOf(intVal) > -1 ? newEntryCategoriesIds.filter(c => c!= intVal) : [...newEntryCategoriesIds,intVal];
    this.setState({
      newEntryCategoriesIds: categories,
    })
  }

  renderCategory(c,k){
    const {newEntryCategoriesIds} = this.state;
    return (
      <Form.Check
        type="checkbox"
        key={`checkbox${k}`}
        label={c.name}
        value={c.id}
        checked={newEntryCategoriesIds.indexOf(c.id)>-1}
        onChange={this.toggleCategory}
      />
    )
  }

  submitNewEntry(ev){
    ev.preventDefault();
    this.setState({
      isFetching: true,
    })
    const {newEntryTitle,newEntryContent,newEntryCategoriesIds} = this.state;
    const data = {
      title: newEntryTitle,
      content: newEntryContent,
      categories: newEntryCategoriesIds,
    }
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/user/api/blog-entry/new`,'POST',data)
      .then(data => {
        if(data.success){
          this.clearForm();
          this.setState({
            isSubmitted: true,
          });
        }
      })
      .catch(err => console.warn(err))
      .finally(() => this.setState({
        isFetching: false,
      }))
  }

  clearForm(){
    this.setState({
      newEntryTitle: '',
      newEntryContent: '',
      newEntryCategoriesIds: [],
    })
  }

  renderNewStoryForm(){
    const {categories,newEntryTitle,newEntryContent} = this.state;
    return (
      <Form onSubmit={this.submitNewEntry}>
        <Form.Group>
          <Form.Label>Tytuł:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Tytuł"
            name="newEntryTitle"
            value={newEntryTitle}
            onChange={this.updateItem}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Treść</Form.Label>
          <Form.Control
            as="textarea"
            rows={10}
            name="newEntryContent"
            value={newEntryContent}
            onChange={this.updateItem}
          />
        </Form.Group>
        <h5>Wybierz kategorie do których chcesz dodać swój wpis:</h5>
        {categories.map((c,k)=>this.renderCategory(c,k))}
        <Button type="submit" variant="error">Wyślij nam swoją historię</Button>
    </Form>
    )
  }

  render(){
    const {isLoading,isSubmitted} = this.state;
    return (
      <main>
        <h2>Dodaj nową historię</h2>
        {isSubmitted && (<Alert variant="success">Twój wpis został przesłany do moderacji</Alert>)}
        {this.renderNewStoryForm()}
      </main>
    )
  }
}
