import React from 'react';
import './StaticPageComponent.scss';
import { Button } from 'react-bootstrap';
import Logo from '../assets/logo_blank.png';
import { Link } from 'react-router-dom';
import {fetchData} from '../services';
import GoogleStore from '../assets/store-google.png';
import AppStore from '../assets/store-ios.png';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

export default class StaticPageComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isFetching: false,
      content: '',
    }
    this.fetchContent = this.fetchContent.bind(this);
  }

  componentDidMount(){
    this.fetchContent();
  }

  componentDidUpdate(prevProps){
    if(prevProps.contentType !== this.props.contentType){
      this.fetchContent();
    }
  }

  fetchContent(){
    const {contentType} = this.props;
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/static-pages/${contentType}`)
      .then(data=>{
        if(data) this.setState({
          content: data.content,
        });
      })
      .finally(()=>this.setState({isLoading:false}));
  }

  render(){
    const {content} = this.state;
    return (
      <main className="front-end">
        <header>
          <Link to="/">
            <img src={Logo} alt="Find Out!" />
          </Link>
          <nav>
            <Link to="/pakiety">Pakiety</Link>
            <Link to="/bezpieczenstwo">Bezpieczeństwo</Link>
            <Link to="/blog">Blog</Link>
          </nav>
          <Link to="/find-out" className="login-link">
            <Button>Logowanie</Button>
          </Link>
        </header>
        <section dangerouslySetInnerHTML={{__html: content}}/>
        <footer>
          <h5>Do pobrania</h5>
          <div className="mobile-stores">
            <img src={GoogleStore} alt="Google Store" />
            <img src={AppStore} alt="App Store" />
          </div>
          <nav>
            <ul>
              <li><Link to="/o-nas">O nas</Link></li>
              <li><Link to="/reklama">Reklama</Link></li>
              <li><Link to="/kontakt">Kontakt</Link></li>
              <li><Link to="/pomoc">Pomoc</Link></li>
            </ul>
          </nav>
        </footer>
      </main>
    )
  }
}
