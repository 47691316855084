import React from 'react';
import { Button } from 'react-bootstrap';
import './HomeComponent.scss';
import LoaderComponent from '../../Loader/LoaderComponent';
import Logo from '../../assets/logo_blank.png';
import {fetchData} from '../../services';
import {Link} from 'react-router-dom';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

export default class SelectPackageComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      packages: [],
      isLoading: false,
    }
    this.fetchPackages = this.fetchPackages.bind(this);
    this.renderSingleCheck = this.renderSingleCheck.bind(this);
    this.renderPackages = this.renderPackages.bind(this);
  }

  componentDidMount(){
    this.fetchPackages();
  }

  fetchPackages(){
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/user-access/types`)
      .then(data=>{
        if(data) this.setState({
          packages: data.userAccessTypes,
        })
      })
      .finally(() => this.setState({isLoading:false}));
  }

  initiatePayment(id){
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/user/api/payment/init/${id}`,'POST')
      .then(data=>{
        if(data){
          if(data.token) window.location.replace(`https://sandbox.przelewy24.pl/trnRequest/${data.token}`);
          else if(data.errorMessage) window.alert(data.errorMessage);
        }
      })
      .finally(() => this.setState({isLoading:false}));
  }

  renderSingleCheck(){
    const {usedSingleCheck} = this.props;
    const label = `${usedSingleCheck ? 'Zobacz' : 'Wykonaj'} swoje bezpłatne sprawdzenie`;
    return (
      <Button variant="warning">
        <Link to="/find-out/free-check">{label}</Link>
      </Button>
    )
  }

  renderPackages(p,k){
    return (
      <Button key={k} variant="danger" onClick={()=>this.initiatePayment(p.id)}>
        {p.name} <span>({p.cost} PLN, {p.days} dni)</span>
      </Button>
    )
  }

  render(){
    const {isLoading} = this.state;
    return isLoading ? (<LoaderComponent/>) : (
        <div className="select-package-component">
          <img src={Logo} alt="Logo" />
          <h3>Wybierz swój plan:</h3>
          <small>{this.props.username}</small>
          {this.renderSingleCheck()}
          <div className="packages">
            {this.state.packages.map((p,k) => this.renderPackages(p,k))}
          </div>
          <Link className="mainmenu" to="/logout"><span>Wyloguj</span></Link>
        </div>
      );
  }
}
