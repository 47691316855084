import React from 'react';
import {Table,Button} from 'react-bootstrap';
import {fetchData} from '../../services';
const {REACT_APP_BACKEND_URL} = process.env;

export default class PaymentsComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      payments: [],
    }
    this.fetchPayments = this.fetchPayments.bind(this);
  }

  componentDidMount(){
    this.fetchPayments();
  }

  fetchPayments(){
    fetchData(`${REACT_APP_BACKEND_URL}/api/payment/all`)
      .then(data=>this.setState({
        payments:data.payments,
      }))
      .catch(err=>console.warn(err));
  }

  removePayments(paymentId){
    if(window.confirm("Usunąć wybraną płatność?")){
      if(window.confirm("Płatność powinno się usuwać bardzo ostrożnie, np. z przyczyn testowych. Czy usunąć?")){
        fetchData(`${REACT_APP_BACKEND_URL}/api/payment/remove/${paymentId}`,'DELETE')
          .then(data=>!!data.success && this.fetchPayments())
          .catch(err=>console.warn(err));
      }
    }
  }

  renderSinglePayment(p,k){
    return (
      <tr key={k}>
        <td>{k+1}</td>
        <td>{p.id}<Button variant="warning" onClick={()=>this.removePayments(p.id)}>Usuń</Button></td>
        <td>{p.uid}</td>
        <td>{p.owner}</td>
        <td>{p.initialTime}</td>
        <td>{p.accessType}</td>
        <td>{p.p24Description}</td>
        <td>{p.p24ConfirmationTime}</td>
      </tr>
    )
  }

  renderPayments(){
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>LP</th>
            <th>Id</th>
            <th>Uid</th>
            <th>Właściciel</th>
            <th>Czas rozpoczęcia</th>
            <th>Typ pakietu</th>
            <th>Opis przekazany do P24</th>
            <th>Potwierdzenie z P24</th>
          </tr>
        </thead>
        <tbody>
          {this.state.payments.map((u,k) => this.renderSinglePayment(u,k))}
        </tbody>
      </Table>
    )
  }

  render(){
    return (
      <div className="payments-list">
        <h3>Płatności:</h3>
        {this.renderPayments()}
      </div>
    )
  }
}
