import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './assets/logo_blank.png';
import Partners from './assets/partners.png';
import Mobile from './assets/mobile.png';
import Facebook from './assets/social-facebook.png';
import Youtube from './assets/social-youtube.png';
import Instagram from './assets/social-instagram.png';
import Something from './assets/social-something.png';
import GoogleStore from './assets/store-google.png';
import AppStore from './assets/store-ios.png';
import { Button, Carousel } from 'react-bootstrap';
import "./FrontEndComponent.scss";
import { fetchData } from './services';

const { REACT_APP_BACKEND_OPEN_URL } = process.env;
const INITIAL_LABEL_WIDTH = 45;
const INITIAL_LABEL_SPEED = 50;

export default class FrontEndComponent extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      labelWidth: INITIAL_LABEL_WIDTH,
      labelChangeDirection: -1,
      activeCarouselIndex: 0,
      labels: [],
      socialMedia: {},
    }
    this.modifyLabelWidth = this.modifyLabelWidth.bind(this);
  }

  componentDidMount(){
    this.interval = setInterval(this.modifyLabelWidth,INITIAL_LABEL_SPEED);
    this.getLabels();
    this.getSocialMedia();
  }

  componentWillUnmount(){
    clearInterval(this.interval);
  }

  getLabels(){
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/settings/get-labels`)
      .then(data=>this.setState({
        labels:data.labels,
      }))
      .catch(err=>console.warn(err));
  }

  getSocialMedia(){
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/settings/get-social-media`)
      .then(data=>this.setState({
        socialMedia:data.socialmedia,
      }))
      .catch(err=>console.warn(err));
  }

  modifyLabelWidth(){
    const {labelWidth, labelChangeDirection, activeCarouselIndex, labels} = this.state;
    let newActiveCarouselIndex = activeCarouselIndex;
    if(0>=labelWidth){
      if(0==labelWidth){
        newActiveCarouselIndex = (labels?.length-1<=newActiveCarouselIndex) ? 0 : newActiveCarouselIndex+1;
      }
      this.setState({
        labelWidth: labelWidth+labelChangeDirection,
        labelChangeDirection: 1,
        activeCarouselIndex: newActiveCarouselIndex,
      });
    } else if (INITIAL_LABEL_WIDTH<=labelWidth){
      this.setState({
        labelWidth: labelWidth+labelChangeDirection,
        labelChangeDirection: -1,
      });
    } else this.setState({
      labelWidth: labelWidth+labelChangeDirection,
    })
  }

  render() {
    const {labelWidth,activeCarouselIndex,labels,socialMedia} = this.state;
    return (
      <main className="front-end">
        <header>
          <Link to="/">
            <img src={Logo} alt="Find Out!" />
          </Link>
          <nav>
            <Link to="/pakiety">Pakiety</Link>
            <Link to="/bezpieczenstwo">Bezpieczeństwo</Link>
            <Link to="/blog">Blog</Link>
          </nav>
          <Link to="/find-out" className="login-link">
            <Button>Logowanie</Button>
          </Link>
        </header>
        <section>
          <Carousel slide={false} indicators={false} interval={null} activeIndex={activeCarouselIndex} style={{width: labelWidth + 'rem'}}>
            {labels.map((k,l) => (
              <Carousel.Item key={`label${l}`}>
                <Carousel.Caption>
                  <h3>{k}<span className="caret" style={{visibility:labelWidth%2 ? 'visible' : 'hidden'}}>_</span></h3>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
          <div className="images">
            <img src={Partners} alt="Relacja" />
            <img src={Mobile} alt="Aplikacja" />
          </div>
          <div className="right-section">
            <nav>
              <Link to="/find-out">
                <Button className="login">Logowanie</Button>
              </Link>
              <Link to="/find-out/register">
                <Button className="register">Rejestracja</Button>
              </Link>
            </nav>
            <div className="socials">
              <h5>Śledź nas:</h5>
              <div className="social-icons">
                <Link to={socialMedia['facebook'] || '/'} target="_blank">
                  <img src={Facebook} alt="Facebook" />
                </Link>
                <Link to={socialMedia['youtube'] || '/'} target="_blank">
                  <img src={Youtube} alt="Youtube" />
                </Link>
                <Link to={socialMedia['instagram'] || '/'} target="_blank">
                  <img src={Instagram} alt="Instagram" />
                </Link>
                <Link to={socialMedia['tiktok'] || '/'} target="_blank">
                  <img src={Something} alt="Something" />
                </Link>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <h5>Do pobrania</h5>
          <div className="mobile-stores">
            <img src={GoogleStore} alt="Google Store" />
            <img src={AppStore} alt="App Store" />
          </div>
          <nav>
            <ul>
              <li><Link to="/o-nas">O nas</Link></li>
              <li><Link to="/reklama">Reklama</Link></li>
              <li><Link to="/kontakt">Kontakt</Link></li>
              <li><Link to="/pomoc">Pomoc</Link></li>
            </ul>
          </nav>
        </footer>
      </main>
    );
  }
}
