import React from 'react';
import './Dashboard.scss';
import { Outlet, Navigate } from "react-router-dom";
import {fetchData} from "../services";
import MenuComponent from '../Menu/MenuComponent';
import Logo from '../assets/logo_blank.png';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

export default class DashboardComponent extends React.Component {
  constructor(props){
    super(props);
    this.state={
      isMinimized: true,
      redirectToLogin: false,
      redirectToUser: false,
    }
    this.toggleMinimize = this.toggleMinimize.bind(this);
  }

  componentDidMount(){
    this.fetchUserData();
  }

  fetchUserData(){
    this.setState({isLoading: true});
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/me`,'GET',null,false)
      .then(data=>{
        if(data){
          this.setState({
            redirectToLogin: !data.isLoggedIn,
            redirectToUser: !data.isAdmin,
          })
        }
      })
      .finally(()=>this.setState({isLoading:false}));
  }

  toggleMinimize(){
    this.setState({
      isMinimized: !this.state.isMinimized,
    })
  }

  render(){
    const {isMinimized,redirectToUser,redirectToLogin} = this.state;
    return (
      <div className={`Dashboard${isMinimized ? ' minimized' : ''}`}>
        {redirectToUser && (<Navigate to="/" redirect={true} />)}
        {redirectToLogin && (<Navigate to="/find-out/login" redirect={true} />)}
        <span className="toggle-minimize" onClick={this.toggleMinimize}>
          {this.state.isMinimized ? 'Rozwiń menu' : 'Zamknij menu'}
        </span>
        <nav>
          <img src={Logo} className="logo" alt="Logo Klubu"/>
          <MenuComponent/>
        </nav>
        <section>
          <Outlet/>
        </section>
      </div>
    );
  }
}
