import React from 'react';
import './App.scss';
import { Routes, Route, Navigate } from "react-router-dom";
// universal components
import LoginComponent from './Login/LoginComponent';
import ResetComponent from './Reset/ResetComponent';
import SetPasswordComponent from './Reset/SetPasswordComponent';
import RegisterComponent from './Register/RegisterComponent';
import ConfirmAccountComponent from './Register/ConfirmAccountComponent';
import LogoutComponent from './Logout/LogoutComponent';
import FindOutComponent from './FindOutComponent';
import FrontEndComponent from './FrontEndComponent';
import StaticPageComponent from './StaticPages/StaticPageComponent';
import BlogPagesComponent from './BlogPages/BlogPagesComponent';
import EntriesComponent from './BlogPages/EntriesComponent';
import SingleEntryComponent from './BlogPages/SingleEntryComponent';
// admin components
import DashboardComponent from './Dashboard/DashboardComponent';
import UsersComponent from './Components/Users/UsersComponent';
import NewUserComponent from './Components/Users/NewUserComponent';
import PortalsComponent from './Components/Portals/PortalsComponent';
import NewPortalComponent from './Components/Portals/NewPortalComponent';
import RelationTypesComponent from './Components/RelationTypes/RelationTypesComponent';
import NewRelationTypeComponent from './Components/RelationTypes/NewRelationTypeComponent';
import AccessTypesComponent from './Components/AccessTypes/AccessTypesComponent';
import NewAccessTypeComponent from './Components/AccessTypes/NewAccessTypeComponent';
import PaymentsComponent from './Components/Payments/PaymentsComponent';
import PageSettingsComponent from './Components/PageSettings/PageSettingsComponent';
import StaticPagesComponent from './Components/PageSettings/StaticPagesComponent';
import BlogCategoriesComponent from './Components/Blog/BlogCategoriesComponent';
import BlogEntriesComponent from './Components/Blog/BlogEntriesComponent';
// non-admin Components
import HomeComponent from './UserComponents/HomeComponent/HomeComponent';
import MainComponent from './UserComponents/HomeComponent/MainComponent';
import AccountComponent from './UserComponents/HomeComponent/AccountComponent';
import AddRelationComponent from './UserComponents/HomeComponent/AddRelationComponent';
import FreeCheckComponent from './UserComponents/HomeComponent/FreeCheckComponent';
import RelationDetailsComponent from './UserComponents/HomeComponent/RelationDetailsComponent';
import PaymentVerificationComponent from './UserComponents/HomeComponent/PaymentVerificationComponent';
import RelationChatComponent from './UserComponents/HomeComponent/RelationChatComponent';
import NewBlogPageComponent from './BlogPages/NewBlogPageComponent';

class App extends React.Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className="App">
        <Routes>
          <Route path="/admin" element={<DashboardComponent/>} >
            <Route path="/admin" element={<UsersComponent/>}/>
            <Route path="/admin/new-user" element={<NewUserComponent/>}/>
            <Route path="/admin/portals" element={<PortalsComponent/>}/>
            <Route path="/admin/new-portal" element={<NewPortalComponent/>}/>
            <Route path="/admin/new-relation-type" element={<NewRelationTypeComponent/>}/>
            <Route path="/admin/relation-types" element={<RelationTypesComponent/>}/>
            <Route path="/admin/new-access-type" element={<NewAccessTypeComponent/>}/>
            <Route path="/admin/access-types" element={<AccessTypesComponent/>}/>
            <Route path="/admin/payments" element={<PaymentsComponent/>}/>
            <Route path="/admin/page-settings" element={<PageSettingsComponent/>}/>
            <Route path="/admin/static-pages" element={<StaticPagesComponent/>}/>
            <Route path="/admin/blog-categories" element={<BlogCategoriesComponent/>}/>
            <Route path="/admin/entries/to-decide" element={<BlogEntriesComponent entriesType="to-decide"/>}/>
            <Route path="/admin/entries/approved" element={<BlogEntriesComponent entriesType="approved"/>}/>
            <Route path="/admin/entries/denied" element={<BlogEntriesComponent entriesType="denied"/>}/>
          </Route>
          <Route path="/find-out" element={<FindOutComponent />} >
            <Route path="/find-out/app" element={<HomeComponent/>} >
              <Route path="/find-out/app" element={<MainComponent/>}/>
              <Route path="/find-out/app/my-account" element={<AccountComponent/>}/>
              <Route path="/find-out/app/add-relation" element={<AddRelationComponent/>}/>
              <Route path="/find-out/app/relation-details/:id" element={<RelationDetailsComponent/>}/>
              <Route path="/find-out/app/relation-chat/:interlocutorId" element={<RelationChatComponent/>} />
            </Route>
            <Route path="/find-out/free-check" element={<FreeCheckComponent/>}/>
            <Route path="/find-out/payment/:id" element={<PaymentVerificationComponent/>}/>
            <Route path="/find-out/login" element={<LoginComponent/>} />
            <Route path="/find-out/register" element={<RegisterComponent/>} />
            <Route path="/find-out/reset-password" element={<ResetComponent/>} />
            <Route path="/find-out/set-password/:token" element={<SetPasswordComponent/>} />
            <Route path="/find-out/confirm/:id" element={<ConfirmAccountComponent/>} />
            <Route path="/find-out/" element={(<LoginComponent />)}/>
          </Route>
          <Route path="/pakiety" element={<StaticPageComponent contentType="pakiety"/>} />
          <Route path="/bezpieczenstwo" element={<StaticPageComponent contentType="bezpieczenstwo"/>} />
          <Route path="/o-nas" element={<StaticPageComponent contentType="o-nas"/>} />
          <Route path="/reklama" element={<StaticPageComponent contentType="reklama"/>} />
          <Route path="/kontakt" element={<StaticPageComponent contentType="kontakt"/>} />
          <Route path="/pomoc" element={<StaticPageComponent contentType="pomoc"/>} />
          <Route path="/blog" element={<StaticPageComponent contentType="blog"/>} />
          <Route path="/historie" element={<BlogPagesComponent />}>
            <Route path="/historie" element={<Navigate to="/historie/c/0/wszystkie-kategorie" />} />
            <Route path="/historie/c/:category/:queryString" element={<EntriesComponent />} />
            <Route path="/historie/e/:entry/:queryString" element={<SingleEntryComponent />} />
            <Route path="/historie/nowa" element={<NewBlogPageComponent/>} />
          </Route>
          <Route path="/logout" element={<LogoutComponent/>} />
          <Route path="/" element={<FrontEndComponent/>} />
          <Route path="*" element={<Navigate to="/" replace={true}/>} />
        </Routes>
      </div>
    )
  }
}

export default App;
