import Logo from '../assets/logo.png';
import Spinner from 'react-bootstrap/Spinner';
import './Loader.scss';

function LoaderComponent() {
  return (
    <div className="loader">
      <img src={Logo} alt="Loader" />
      <div className="spinner-wrapper">
        <Spinner animation="grow" />
      </div>
    </div>
  )
}

export default LoaderComponent;
