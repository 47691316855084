import React from 'react';
import {Table,Button, Form} from 'react-bootstrap';
import {fetchData} from '../../services';
const {REACT_APP_BACKEND_URL} = process.env;

export default class PageSettingsComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      settings: [],
      newSettingName: '',
      newSettingValue: '',
    }
    this.fetchSettings = this.fetchSettings.bind(this);
    this.addNewSetting = this.addNewSetting.bind(this);
  }

  componentDidMount(){
    this.fetchSettings();
  }

  fetchSettings(){
    fetchData(`${REACT_APP_BACKEND_URL}/api/settings/all`)
      .then(data=>this.setState({
        settings:data.settings,
      }))
      .catch(err=>console.warn(err));
  }

  removeSetting(settingId){
    if(window.confirm("Usunąć wybrane ustawienie?")){
      if(window.confirm("Ustawienia powinno się usuwać bardzo ostrożnie, np. z przyczyn testowych. Czy usunąć?")){
        fetchData(`${REACT_APP_BACKEND_URL}/api/setting/remove/${settingId}`,'DELETE')
          .then(data=>!!data.success && this.fetchSettings())
          .catch(err=>console.warn(err));
      }
    }
  }

  addNewSetting(ev){
    ev.preventDefault();
    const {newSettingName,newSettingValue} = this.state;
    const data = {
      settingName:newSettingName,
      settingValue:newSettingValue,
    };
    fetchData(`${REACT_APP_BACKEND_URL}/api/setting/new`,'POST',data)
      .then(data=>!!data.success && this.fetchSettings())
      .catch(err=>console.warn(err));
  }

  renderSingleSetting(s,k){
    return (
      <tr key={k}>
        <td>{k+1}</td>
        <td>{s.id}<Button variant="warning" onClick={()=>this.removeSetting(s.id)}>Usuń</Button></td>
        <td>{s.settingName}</td>
        <td>{s.settingValue}</td>
      </tr>
    )
  }

  renderSettings(){
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>LP</th>
            <th>Id</th>
            <th>Nazwa</th>
            <th>Wartość</th>
          </tr>
        </thead>
        <tbody>
          {this.state.settings.map((u,k) => this.renderSingleSetting(u,k))}
        </tbody>
      </Table>
    )
  }

  updateItem(item,value){
    this.setState({
      [item]:value,
    })
  }

  renderAddSettingPanel(){
    const {newSettingName,newSettingValue} = this.state;
    return (
      <Form onSubmit={this.addNewSetting}>
        <Form.Group>
          <Form.Label>Nazwa ustawienia</Form.Label>
          <Form.Control
            type="Text"
            value={newSettingName}
            placeholder="Nazwa ustawienia"
            onChange={(ev)=>this.updateItem('newSettingName',ev.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Wartość ustawienia</Form.Label>
          <Form.Control
            as="textarea"
            value={newSettingValue}
            onChange={ev=>this.updateItem('newSettingValue',ev.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Dodaj
        </Button>
      </Form>
    )
  }

  render(){
    return (
      <div className="settings-list">
        <h3>Ustawienia strony:</h3>
        {this.renderSettings()}
        {this.renderAddSettingPanel()}
        <ul>
          <li>Wartości dostępne dla social media: 'facebook','instagram','youtube','snapchat','pinterest','linkedin','tiktok'</li>
          <li>Aby dodać napis na stronie głównej należy użyć klucza zawierającego wyrażenie "label"</li>
        </ul>
      </div>
    )
  }
}
