import React from 'react';
import "./RelationTypesComponent.scss";
import {Table,Button,Form} from 'react-bootstrap';
import {fetchData} from '../../services';
import LoaderComopnent from '../../Loader/LoaderComponent';
const {REACT_APP_BACKEND_URL} = process.env;

export default class RelationTypesComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      relationTypes: [],
      isLoading: false,
    }
    this.fetchRelationTypes = this.fetchRelationTypes.bind(this);
    this.renderRelationTypes = this.renderRelationTypes.bind(this);
  }

  componentDidMount(){
    this.fetchRelationTypes();
  }

  fetchRelationTypes(){
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_URL}/api/relation/types`)
      .then(data=>this.setState({
        relationTypes:data.relationTypes,
      }))
      .catch(err=>console.warn(err))
      .finally(()=>this.setState({isLoading:false}));
  }

  removeRelationType(id){
    if(window.confirm("Czy usunąć?")){
      this.setState({isLoading:true});
      fetchData(`${REACT_APP_BACKEND_URL}/api/relation/types/${id}`,'DELETE')
        .then(data=>this.fetchRelationTypes())
        .catch(err=>console.warn(err))
        .finally(()=>this.setState({isLoading:false}));
    }
  }

  updateOrderNumber(ev,rtId){
    const {target:{value}} = ev;
    const data = {'orderNumber':value};
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_URL}/api/relation/types/${rtId}/update-on`,'PATCH',data)
      .then(data=>this.fetchRelationTypes())
      .catch(err=>console.warn(err.errorMessage))
      .finally(()=>this.setState({isLoading:false}));
  }

  renderSingleRelationType(r,k){
    const {isLoading} = this.state;
    return (
      <tr key={k}>
        <td>
          {k+1}
          <Button variant="danger" onClick={()=>this.removeRelationType(r.id)}>Usuń</Button>
        </td>
        <td>
          <Form.Control
            type="number"
            onChange={(ev)=>this.updateOrderNumber(ev,r.id)}
            value={r.orderNumber || 1}
            disabled={isLoading}
          />
        </td>
        <td>{r.name}</td>
        <td>
          {r.relationsNumber}
        </td>
      </tr>
    )
  }

  renderRelationTypes(){
    return (
      <Table striped bordered hover>
        <thead>
          <tr><th>LP</th><th>Kolejność</th><th>Nazwa</th><th>Ilość relacji</th></tr>
        </thead>
        <tbody>
          {this.state.relationTypes.map((r,k) => this.renderSingleRelationType(r,k))}
        </tbody>
      </Table>
    )
  }

  render(){
    const {isLoading} = this.state;
    return isLoading ? (<LoaderComopnent/>) : (
      <div className="portals-list">
        <h3>Dostępne typy relacji:</h3>
        {this.renderRelationTypes()}
      </div>
    );
  }
}
