import React from 'react';
import {Table,Button} from 'react-bootstrap';
import {fetchData} from '../../services';
import LoaderComponent from '../../Loader/LoaderComponent';
const {REACT_APP_BACKEND_URL} = process.env;

export default class BlogEntriesComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      entries: [],
      isFetching: false,
      denyReason: null,
    }
    this.fetchEntries = this.fetchEntries.bind(this);
    this.fetchEntriesByStatus = this.fetchEntriesByStatus.bind(this);
    this.changeEntryStatus = this.changeEntryStatus.bind(this);
  }

  componentDidMount(){
    this.fetchEntries();
  }

  componentDidUpdate(prevProps){
    if(prevProps.entriesType !== this.props.entriesType){
      this.fetchEntries();
    }
  }

  fetchEntries(){
    const {entriesType} = this.props;
    switch(entriesType){
      case('to-decide'): this.fetchEntriesByStatus('to-decide'); break;
      case('approved'): this.fetchEntriesByStatus('approved'); break;
      case('denied'): this.fetchEntriesByStatus('denied'); break;
    }
  }

  fetchEntriesByStatus(status){
    this.setState({isFetching: true});
    fetchData(`${REACT_APP_BACKEND_URL}/api/blog-entry/${status}`)
      .then(data=>this.setState({
        entries:data.entries,
      }))
      .catch(err=>console.warn(err))
      .finally(()=>this.setState({isFetching:false}));
  }

  changeEntryStatus(id,status){
    let message = 'Czy na pewno ';
    message+= 'approve' === status ? 'zaakceptować' : 'odrzucić';
    message+= ' wpis?';
    let denyReason = null;
    if(window.confirm(message)){
      if('decline'===status) denyReason = window.prompt('Podaj przyczynę odrzucenia:');
      const data = {
        denyReason,
      }
      fetchData(`${REACT_APP_BACKEND_URL}/api/blog-entry/${id}/${status}`,'PATCH',data)
        .then(data=>!!data.success && this.fetchEntries())
        .catch(err=>console.warn(err));
    }
  }

  removeEntry(id){
    if(window.confirm('Usunąć?')){
      this.setState({isFetching:true})
      fetchData(`${REACT_APP_BACKEND_URL}/api/blog-entry/${id}`,'DELETE')
        .then(data=>!!data.success && this.fetchEntries())
        .catch(err=>console.warn(err))
        .finally(()=>this.setState({isFetching:false}));
    }
  }

  renderSingleEntry(e,k){
    const {entriesType} = this.props;
    return (
      <tr key={`entry=${k}`}>
        <td>{k+1}</td>
        <td>{e.title}</td>
        <td>{e.content}</td>
        <td><ul>{e.categories.map((c,k) => (<li key={`e${e.id}c${k}`}>{c.name}</li>))}</ul></td>
        <td>{e.decisionTime}</td>
        <td>{e.decisionMaker}</td>
        <td>{e.denyReason}</td>
        <td>
          {'approved'!==entriesType && (<Button variant="success" onClick={()=>this.changeEntryStatus(e.id,'approve')}>Zaakceptuj</Button>)}
          <br/>
          {'denied'!==entriesType && (<Button variant="warning" onClick={()=>this.changeEntryStatus(e.id,'decline')}>Odrzuć</Button>)}
          <br/>
          <Button variant="danger" onClick={()=>this.removeEntry(e.id)}>Usuń</Button>
        </td>
      </tr>
    )
  }

  renderEntries(){
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>LP</th>
            <th>Tytuł</th>
            <th>Treść</th>
            <th>Kategorie</th>
            <th>Data akceptacji/odrzucenia</th>
            <th>Użytkownik podejmujący decyzję</th>
            <th>Przyczyna odrzucenia</th>
            <th>Decyzje</th>
          </tr>
        </thead>
        <tbody>
          {this.state.entries.map((e,k) => this.renderSingleEntry(e,k))}
        </tbody>
      </Table>
    )
  }

  render(){
    const {entries,isFetching} = this.state;
    const {entriesType} = this.props;
    let label = "Wpisy ";
    switch(entriesType){
      case("to-decide"):
        label+="do zaakceptowania lub odrzucenia:";break;
      case("approved"):
        label+="zaakceptowane:";break;
      case("denied"):
        label+="odrzucone:";break;
    }
    return isFetching ? (<LoaderComponent/>) : (
        <div className="entries-list">
          <h3>{label}</h3>
          {!!entries.length ? this.renderEntries() : (<h2>Brak oczekujących wpisów!</h2>)}
        </div>
    );
  }
}
