import React from 'react';
import "./AccessTypesComponent.scss";
import {Table,Button,Form} from 'react-bootstrap';
import {fetchData} from '../../services';
import LoaderComopnent from '../../Loader/LoaderComponent';
const {REACT_APP_BACKEND_URL} = process.env;

export default class AccessTypesComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      userAccessTypes: [],
      isLoading: false,
    }
    this.fetchUserAccessTypes = this.fetchUserAccessTypes.bind(this);
    this.renderUserAccessTypes = this.renderUserAccessTypes.bind(this);
    this.updateOrderNumber = this.updateOrderNumber.bind(this);
  }

  componentDidMount(){
    this.fetchUserAccessTypes();
  }

  fetchUserAccessTypes(){
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_URL}/api/user-access/types`)
      .then(data=>this.setState({
        userAccessTypes:data.userAccessTypes,
      }))
      .catch(err=>console.warn(err))
      .finally(()=>this.setState({isLoading:false}));
  }

  removeUserAccessType(id){
    if(window.confirm("Czy usunąć?")){
      this.setState({isLoading:true});
      fetchData(`${REACT_APP_BACKEND_URL}/api/user-access/types/${id}`,'DELETE')
        .then(data=>this.fetchUserAccessTypes())
        .catch(err=>console.warn(err))
        .finally(()=>this.setState({isLoading:false}));
    }
  }

  updateOrderNumber(ev,uatId){
    const {target:{value}} = ev;
    const data = {'orderNumber':value};
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_URL}/api/user-access/types/${uatId}/update-on`,'PATCH',data)
      .then(data=>this.fetchUserAccessTypes())
      .catch(err=>console.warn(err.errorMessage))
      .finally(()=>this.setState({isLoading:false}));
  }

  renderSingleUserAccessType(uat,k){
    const {isLoading} = this.state;
    return (
      <tr key={k}>
        <td>
          {k+1}
          <Button variant="danger" onClick={()=>this.removeUserAccessType(uat.id)}>Usuń</Button>
        </td>
        <td>
          <Form.Control
            type="number"
            onChange={(ev)=>this.updateOrderNumber(ev,uat.id)}
            value={uat.orderNumber || 1}
            disabled={isLoading}
          />
        </td>
        <td>{uat.name}</td>
        <td>{uat.days}</td>
        <td>{uat.cost} PLN</td>
        <td>{uat.packagesNumber}</td>
      </tr>
    )
  }

  renderUserAccessTypes(){
    return (
      <Table striped bordered hover>
        <thead>
          <tr><th>LP</th><th>Kolejność</th><th>Nazwa</th><th>Ile dni</th><th>Koszt w PLN</th><th>Ilość aktywnych pakietów</th></tr>
        </thead>
        <tbody>
          {this.state.userAccessTypes.map((u,k) => this.renderSingleUserAccessType(u,k))}
        </tbody>
      </Table>
    )
  }

  render(){
    const {isLoading} = this.state;
    return isLoading ? (<LoaderComopnent/>) : (<div className="portals-list">
        <h3>Dostępne pakiety dostępu:</h3>
        {this.renderUserAccessTypes()}
      </div>
    );
  }
}
