import React from 'react';
import { Form } from 'react-bootstrap';
import './Form.scss';
import '../HomeComponent.scss';
import {placeholders} from '../relation.data';
import Partners from '../../../assets/partners.png';
import Logo from '../../../assets/logo_blank.png';
import { Link } from 'react-router-dom';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

export default class RelationDetailsFormComponent extends React.Component {
  constructor(props){
    super(props);
    this.renderRelation = this.renderRelation.bind(this);
    this.renderRelationDetails = this.renderRelationDetails.bind(this);
    this.onSelectPerson = this.onSelectPerson.bind(this);
  }

  renderPerson(p,k){
    const label = `${p.userNickname || 'Brak nickname'} (${p.userEmail || 'Brak emaila'})`;
    return (
      <option key={k} value={p.userId}>{label}</option>
    )
  }

  onSelectPerson(ev){
    this.props.onSelectPerson && this.props.onSelectPerson(ev);
  }

  renderRelation(r,k){
    const {relationDetails:{label}} = this.props;
    let isCurrent = r.name === label.relationType;
    return (
      <div className="relation" key={k}>
        <h4 className={`${isCurrent ? 'current' : ''}`}>
          {r.name}:
          {isCurrent && <img src={Partners} alt="Twoja relacja" />}
        </h4>
        <h5><span>{r.persons.length}</span> osób</h5>
        {!!r.persons.length && (
          <Form.Select onChange={this.onSelectPerson}>
            <option>Wybierz osobę</option>
            {r.persons.map((p,k) => this.renderPerson(p,k))}
          </Form.Select>
        )}
      </div>
    )
  }

  renderRelationDetails(){
    const {relationDetails:{label}} = this.props;
    return (
      <>
        &nbsp;osobę która spełnia wszystkie poniższe kryteria:
        {Object.keys(label).filter(rd => label[rd] && placeholders[rd]).map((rd,k) => {
          return (
            <strong key={k}>
              {placeholders[rd]}: {label[rd]}
            </strong>
          )
        })}
      </>)
  }

  renderPortalDetails(){
    const {relationDetails:{portalId}} = this.props;
    return (
      <>
        &nbsp;na
        <img src={`${REACT_APP_BACKEND_OPEN_URL}/api/portals/logo/${portalId}`} alt="Wybrany portal" />
      </>
    )
  }

  render(){
    const {relations,relationDetails:{userName,portalId,label}} = this.props;
    return (
      <div className="relation-details-component">
        <img src={Logo} alt="Logo" className="logo" />
        <small>{userName}</small>
        <h3>
          Sprawdzamy
          {portalId && this.renderPortalDetails()}
          {!!label && this.renderRelationDetails()}
          {!!label && label.lastCheck && (<strong className="last-check">Ostatnio sprawdzono: {label.lastCheck.date}</strong>)}
        </h3>
        {Object.keys(relations).map((r,k) => this.renderRelation(relations[r],k))}
        <Link className="mainmenu" to="/find-out/app"><span>Menu główne</span></Link>
      </div>
    );
  }
}
