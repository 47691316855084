export const basicUser = {
  relationName: '',
  surname: '',
  phone: '',
  email: '',
  nickname: '',
};
export const communicators = {
  messenger: '',
  instagram: '',
  whatsapp: '',
  snapchat: '',
};
export const settings = {
  relationType: 0,
  portal: '',
}
export const placeholders = {
  relationName: 'Imię',
  surname: 'Nazwisko',
  phone: 'Numer telefonu',
  email: 'Email',
  nickname: 'Nickname',
  messenger: 'Messenger',
  instagram: 'Instagram',
  whatsapp: 'Whatsapp',
  snapchat: 'Snapchat',
}
export const disabledEdition = [];
export const basicUserKeys = Object.keys(basicUser);
export const communicatorsKeys = Object.keys(communicators);
export const settingsKeys = Object.keys(settings);
export const configKeys = [...basicUserKeys,...communicatorsKeys,...settingsKeys];
